import {useRef, useLayoutEffect, useMemo} from "react";

export const useDebounce = (callback, delay) => {
  const callbackRef = useRef(callback);
  const timerRef = useRef(null);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  return useMemo(
    () =>
      (...args) => {
        const naiveDebounce = (func, delayMs, ...args) => {
          clearTimeout(timerRef.current);
          timerRef.current = setTimeout(() => {
            func(...args);
          }, delayMs);
        };
        return naiveDebounce(callbackRef.current, delay, ...args);
      },
    [delay],
  );
};
