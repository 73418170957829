import { PropTypes } from "prop-types";
import { triggerEvent } from "@/utils/custom-events";
import { useTranslation } from "react-i18next";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import useScrollLock from "@/hooks/useScrollLock";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import useCustomEventListener from "@/hooks/useCustomEventListener";
import { cn } from "@/utils/cn";
import { useDebounce } from "@/hooks/useDebounce";
import HTMLParser from "../HTMLParser";
import { overlay } from "@/store/Overlay";
import useOverlay from "@/hooks/useOverlay";
import Animate from "../Animate";
import useIsMobile from "@/hooks/useIsMobile";

const headerEl = document.querySelector("header");

const HeaderMenu = ({
  id,
  menus,
  cardImage,
  cardHeadline,
  cardButtonText,
  cardText,
  cardButtonUrl,
  socialsHTML,
  enableGradient,
  enableSearch,
}) => {
  const [open, setOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [mobileHeight, setMobileHeight] = useState(0);
  const mobileMenuRef = useRef(null);
  const mobileLevel2Ref = useRef(null);
  const desktopLevel2Ref = useRef(null);
  const isMobile = useIsMobile();

  const shopCollections = useMemo(() => {
    return (
      menus?.reduce(
        (acc, el) =>
          el.url?.indexOf("#dynamic") !== -1 ? acc.concat(el?.children) : acc,
        [],
      ) ?? []
    );
  }, [menus]);

  const defaultCategoryData = useMemo(() => {
    return shopCollections ? shopCollections[0] : null;
  }, [shopCollections]);

  const displayedCategoryData = activeCategory ?? defaultCategoryData;

  // Lock side scrolling.
  useScrollLock(open);

  useEffect(() => {
    if (open) {
      headerEl.classList.add(`header-menu-${id}`);
      headerEl.classList.add("header-menu-open");
      setMobileHeight(
        window.innerHeight -
        mobileMenuRef.current?.getBoundingClientRect().top || 0,
      );
    } else {
      headerEl.classList.remove(`header-menu-${id}`);
      headerEl.classList.remove("header-menu-open");
      setActiveCategory(null);
    }
  }, [open, id]);

  function handleToggle(detail) {
    const isOpen = detail?.open;

    setOpen(isOpen === undefined ? !open : isOpen);
  }

  function closeMenu() {
    triggerEvent(`${id}:toggle`, {
      open: false,
    });
  }

  overlay.setOnMouseOver(closeMenu);

  useOverlay(open);

  useCustomEventListener(`${id}:toggle`, handleToggle);
  useCustomEventListener("modals:close", () => setOpen(false));

  return (
    <CSSTransition
      nodeRef={mobileMenuRef}
      in={open}
      timeout={400}
      mountOnEnter
      unmountOnExit
      classNames="fade"
    >
      <div className="flex w-full justify-center">
        <div className="relative flex w-full max-w-[90rem]">
          <div
            id="header-menu"
            ref={mobileMenuRef}
            style={{ height: `${mobileHeight}px` }}
            className={`header-menu-modal absolute z-header w-full overflow-hidden bg-transparent text-deep-green md:left-8 md:!h-auto md:max-h-[calc(100vh-122px)] md:max-w-[90%] md:overflow-auto 2xl:max-w-[90rem]`}
          >
            <TopMenu enableSearch={enableSearch} />
            <div
              className={`swipeable-content mx-auto max-w-[90rem] bg-transparent md:flex md:h-[calc(40.562rem)]`}
            >
              {/* level 1 */}
              <div
                className={`level1 mt-[52px] flex flex-col justify-between gap-6 overflow-auto bg-gray px-4 py-8 md:mt-0 md:min-w-[360px] md:p-8 lg:w-full lg:max-w-[400px]
            ${activeCategory ? "md:rounded-bl-3xl md:border-r md:border-dark-green" : "md:rounded-b-3xl"}`}
              >
                <div className="flex flex-col gap-8 md:gap-12">
                  {menus?.map(menu => (
                    <Menu
                      key={menu.title}
                      menu={menu}
                      displayedCategoryData={displayedCategoryData}
                      setActiveCategory={setActiveCategory}
                    />
                  ))}
                </div>

                <HTMLParser html={socialsHTML} />
              </div>
              {/* level 2 */}

              <Animate className="lg:max-w-[400px]" in={!!activeCategory}>
                {isMobile ? (
                  <CSSTransition
                    timeout={200}
                    mountOnEnter
                    unmountOnExit
                    in={!!activeCategory}
                    nodeRef={mobileLevel2Ref}
                    classNames={"slide-right"}
                  >
                    <Level2Comp
                      ref={mobileLevel2Ref}
                      setActiveCategory={setActiveCategory}
                      displayedCategoryData={displayedCategoryData}
                      socialsHTML={socialsHTML}
                      activeCategory={activeCategory}
                      cardImage={cardImage}
                    />
                  </CSSTransition>
                ) : (
                  <SwitchTransition>
                    <CSSTransition
                      timeout={200}
                      key={displayedCategoryData.handle}
                      nodeRef={desktopLevel2Ref}
                      classNames={"fade"}
                    >
                      <Level2Comp
                        ref={desktopLevel2Ref}
                        setActiveCategory={setActiveCategory}
                        displayedCategoryData={displayedCategoryData}
                        socialsHTML={socialsHTML}
                        activeCategory={activeCategory}
                        cardImage={cardImage}
                      />
                    </CSSTransition>
                  </SwitchTransition>
                )}
              </Animate>
              {/* Card (level 3) */}
              {cardImage && cardImage.length > 0 ? (
                <div
                  style={{
                    backgroundImage: `url(${cardImage})`,
                    ...(enableGradient && {
                      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url(${cardImage}) lightgray 50% / cover no-repeat`,
                    }),
                  }}
                  className="level3 hidden w-full shrink-[10] flex-col items-start justify-end gap-2 bg-cover bg-left bg-no-repeat p-8 text-white xl:flex"
                >
                  {cardHeadline ? (
                    <div className="text-[36px] leading-10">{cardHeadline}</div>
                  ) : null}
                  {cardText ? (
                    <div className="max-w-[500px] text-[18px] leading-6">
                      {cardText}
                    </div>
                  ) : null}
                  {cardButtonText && cardButtonUrl ? (
                    <a
                      href={cardButtonUrl}
                      className="button button--primary mt-4"
                    >
                      {cardButtonText}
                    </a>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

HeaderMenu.propTypes = {
  id: PropTypes.string.isRequired,
  menus: PropTypes.array,
  cardImage: PropTypes.string,
  cardHeadline: PropTypes.string,
  cardText: PropTypes.string,
  cardButtonText: PropTypes.string,
  cardButtonUrl: PropTypes.string,
  socialsHTML: PropTypes.string,
  enableGradient: PropTypes.bool,
  enableSearch: PropTypes.bool,
};

const Menu = ({ menu, setActiveCategory, displayedCategoryData }) => {
  const [, rawParams] = menu.url.split("#");
  const params = rawParams?.split("--");
  const isMobile = useIsMobile();

  const isVisible = !params?.find(param => param === "hidden");
  const isMobileOnly = !!params?.find(param => param === "mobile");
  const layout = params?.find(param => param === "secondary")
    ? "secondary"
    : params?.find(param => param === "tertiary")
      ? "tertiary"
      : "primary";

  const setCategoryOnHover = useDebounce(collection => {
    if (!isMobile) {
      setActiveCategory(collection);
    }
  }, 100);

  const handleCollectionClick = collection => {
    setActiveCategory(collection);

    if (!isMobile) {
      location.href = collection.url;
    }
  };

  return (
    <div key={menu.handle} className={cn(isMobileOnly && "md:hidden")}>
      {isVisible ? (
        menu?.url?.indexOf("#") === 0 ? (
          <div
            className={cn(
              "text-sm font-semibold not-italic leading-[120%]",
              menu?.url?.indexOf("#dynamic") !== -1
                ? "mb-4 md:mb-2"
                : "mb-3 md:mb-1",
            )}
          >
            {menu.title}
          </div>
        ) : (
          <a
            href={menu.url}
            className={cn(
              "block text-sm font-semibold not-italic leading-[120%]",
              menu?.url?.indexOf("#dynamic") !== -1
                ? "mb-4 md:mb-2"
                : "mb-3 md:mb-1",
            )}
          >
            {menu.title}
          </a>
        )
      ) : (
        <></>
      )}

      {layout === "primary" ? (
        <div
          className={cn(
            menu.children.length !== 0 && "hidden",
            "flex flex-col",
          )}
        >
          {menu.children.map(collection => (
            <a
              key={collection.title}
              onClick={e => {
                e.preventDefault();
                handleCollectionClick(collection);
              }}
              onMouseOver={() => setCategoryOnHover(collection)}
              href={collection.url}
              className={cn(
                "relative block cursor-pointer py-[10px] text-[1.2rem] font-light not-italic leading-[120%] md:py-[9px] md:text-[1.4rem] md:font-normal lg:text-[1.6rem]",
                displayedCategoryData.handle === collection.handle &&
                "displayed-category",
                menu?.children.length > 0 && "with-arrow",
              )}
            >
              <h3>{collection.title}</h3>
            </a>
          ))}
        </div>
      ) : menu.children.length === 0 ? (
        <SimpleLink
          {...menu}
          className={cn(
            layout === "tertiary" &&
            "mb-0 text-base font-normal not-italic leading-[140%] tracking-[0.01rem]",
          )}
        />
      ) : (
        menu.children.map(subMenu => (
          <SimpleLink
            key={subMenu.handle}
            {...subMenu}
            className={cn(
              layout === "tertiary" &&
              "mb-0 text-base font-normal not-italic leading-[140%] tracking-[0.01rem]",
            )}
          />
        ))
      )}
    </div>
  );
};

Menu.propTypes = {
  menu: PropTypes.any,
  displayedCategoryData: PropTypes.any,
  setActiveCategory: PropTypes.func,
};

const SimpleLink = ({ className, title, url }) => (
  <a
    className={cn(
      "mb-[13px] block w-fit pb-3 text-[1rem] font-light not-italic leading-[120%] md:mb-1 md:text-[1.2rem] md:font-normal lg:text-[1.4rem]",
      className,
    )}
    key={title}
    href={url}
  >
    <span className="animated-underline left">{title}</span>
  </a>
);

SimpleLink.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
};

const Level2Comp = forwardRef(
  (
    {
      setActiveCategory,
      displayedCategoryData,
      socialsHTML,
      activeCategory,
      cardImage,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    if (!activeCategory) return null;
    return (
      <div
        ref={ref}
        className={`level2 mt-[52px] flex flex-col overflow-auto bg-gray px-4 py-8 md:mt-0 md:h-[calc(40.562rem)] md:min-w-[25rem] md:p-8 
          ${cardImage && cardImage.length > 0 ? "md:rounded-br-3xl xl:rounded-none" : "md:rounded-br-3xl"}`}
      >
        <div
          className="with-arrow with-arrow--start pb-8 md:hidden"
          onClick={() => setActiveCategory(undefined)}
        ></div>

        <div className="products-list mb-24 md:mb-4">
          <div className="mb-4 mt-5 text-sm font-semibold not-italic leading-[120%] md:hidden">
            <a href={displayedCategoryData.url}>
              {displayedCategoryData.title}
            </a>
          </div>
          {displayedCategoryData?.products.map(product => (
            <a
              key={product.id}
              className="products-list__link flex cursor-pointer items-center gap-4"
              href={product.url}
            >
              <div className="h-16 w-16 flex-shrink-0 cursor-pointer p-2">
                <img
                  className="rounded-lg object-contain"
                  src={product.image}
                  alt={product.title}
                  width="56"
                  height="56"
                />
              </div>
              <h4 className="animated-underline left line-clamp-1 text-ellipsis break-all text-[1.125rem] font-normal not-italic leading-[120%] md:text-[1.5rem]">
                {product.title}
              </h4>
            </a>
          ))}

          <a
            className="flex h-16 items-center px-4"
            href={displayedCategoryData.url}
          >
            <h4 className="animated-underline left line-clamp-1 text-ellipsis break-all text-[1.125rem] font-normal not-italic leading-[120%] md:text-[1.5rem]">
              {t("action.view_all")}
            </h4>
          </a>
        </div>

        <HTMLParser className="pt-4 md:hidden" html={socialsHTML} />
      </div>
    );
  },
);

Level2Comp.propTypes = {
  socialsHTML: PropTypes.any,
  setActiveCategory: PropTypes.func.isRequired,
  displayedCategoryData: PropTypes.object.isRequired,
  activeCategory: PropTypes.object,
  cardImage: PropTypes.string,
};

Level2Comp.displayName = "Level2Comp";

const TopMenu = ({ className, enableSearch = true }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        "flex size-[14px] h-[52px] w-full items-center justify-between bg-[#104234] px-4 text-[#E7E7E0] md:hidden",
        className,
      )}
    >
      <div>
        {enableSearch && (
          <a href="/search" className="flex items-center gap-2">
            <svg
              className=""
              fill="currentColor"
              stroke="currentColor"
              width="16"
              height="16"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12.6445 22.6229C15.1433 22.6229 17.422 21.6977 19.1725 20.1803L28.3249 29.3327L29.3327 28.3249L20.1803 19.1725C21.6977 17.422 22.6229 15.1433 22.6229 12.6445C22.6229 7.13351 18.1554 2.66602 12.6445 2.66602C7.13351 2.66602 2.66602 7.13351 2.66602 12.6445C2.66602 18.1554 7.13351 22.6229 12.6445 22.6229ZM12.6445 4.09151C17.3607 4.09151 21.1974 7.92822 21.1974 12.6445C21.1974 17.3607 17.3607 21.1974 12.6445 21.1974C7.92822 21.1974 4.09151 17.3607 4.09151 12.6445C4.09151 7.92822 7.92822 4.09151 12.6445 4.09151Z" />
            </svg>

            {t("action.search")}
          </a>
        )}
      </div>
      <a
        href="/pages/stay-ai-subscription"
        className="flex items-center gap-2"
      >
        <svg
          className=""
          fill="currentColor"
          stroke="currentColor"
          width="16"
          height="16"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M19.3933 16.4973C21.734 15.272 23.334 12.8247 23.334 9.99935C23.334 5.94935 20.0506 2.66602 16.0007 2.66602C11.9507 2.66602 8.66732 5.94935 8.66732 9.99935C8.66732 12.8247 10.2673 15.272 12.608 16.4973C7.26065 18.0593 3.33398 23.2053 3.33398 29.3327H28.6673C28.6673 23.2053 24.7407 18.0593 19.3933 16.4973ZM10.0007 9.99935C10.0007 6.69068 12.692 3.99935 16.0007 3.99935C19.3093 3.99935 22.0007 6.69068 22.0007 9.99935C22.0007 13.308 19.3093 15.9993 16.0007 15.9993C12.692 15.9993 10.0007 13.308 10.0007 9.99935ZM16.0007 17.3327C21.824 17.3327 26.636 22.008 27.2647 27.9993H4.73665C5.36532 22.008 10.1773 17.3327 16.0007 17.3327Z" />
        </svg>

        {t("action.account")}
      </a>
    </div>
  );
};

TopMenu.propTypes = {
  className: PropTypes.string,
  enableSearch: PropTypes.bool,
};

export default HeaderMenu;
